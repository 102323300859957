<template>
  <tr class="tr-medicament">
    <td class="border-0 border-b border-solid border-gray-300 px-4 py-2">
      <label v-if="medicament._id">{{ medicament.name }}</label>
      <input-medicaments
        v-else
        :set-item-parent="setMedicament"
        v-model="medicament.name"
        :classes="error ? 'border-red-700' : ''"
      ></input-medicaments>
      <p v-if="error && errorMessage" class="text-red-400">
        {{ errorMessage }}
      </p>
    </td>
    <td
      v-if="medicament._id"
      colspan="2"
      class="border-0 border-b border-solid border-gray-300 px-4 py-2 text-center"
    >
      <label v-if="medicament._id"
        >{{ medicament.doses }}
        {{
          $medicamentDosageUnits[medicament.doseUnit]
            ? $medicamentDosageUnits[medicament.doseUnit].unity
            : ""
        }}
        {{
          medicament.prises ? " - " + medicament.prises + " x/ " + "jours" : ""
        }}</label
      >
    </td>
    <td
      v-if="!medicament._id"
      class="border-0 border-b border-solid border-gray-300 px-4 py-2 px-3"
    >
      <div class="flex flex-row">
        <input
          :disabled="medicament._id"
          type="number"
          v-model="medicament.doses"
          class="bg-white text-dokBlue-ultra appearance-none border border-solid border-dokBlue-ultra inline-block py-2 pl-3 pr-8 rounded leading-none  w-full"
          placeholder="Doses"
        />
        <select v-model="medicament.doseUnit">
          <option
            v-for="(unit, key) in $medicamentDosageUnits"
            :key="key"
            :value="key"
            >{{ unit.unity }}</option
          >
        </select>
      </div>
    </td>
    <td
      v-if="!medicament._id"
      class="border-0 border-b border-solid border-gray-300 px-4 py-2"
    >
      <input
        :disabled="medicament._id"
        type="number"
        v-model="medicament.prises"
        class="bg-white text-dokBlue-ultra appearance-none border border-solid border-dokBlue-ultra inline-block py-2 pl-3 pr-8 rounded leading-none  w-full"
        placeholder="Prises/jours"
      />
    </td>
    <td
      class="border-0 border-b border-solid border-gray-300 px-4 py-2 relative"
    >
      <label v-if="medicament._id">{{ medicament.moreInfo }}</label>
      <input
        v-else
        :disabled="medicament._id"
        type="text"
        v-model="medicament.moreInfo"
        class="bg-white text-dokBlue-ultra appearance-none border border-solid border-dokBlue-ultra inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-dokBlue-ultra w-full"
      />
      <button
        v-if="medicament._id"
        @click="removeMedicament"
        class="rmv-line-medicament bg-transparent border-0  rounded-cu text-red-600 cursor-pointer"
      >
        <i class="fas fa-trash"></i>
      </button>
    </td>
    <td
      class="border-0 border-b-1 border-solid border-gray-300 px-4 py-2"
      style="width:34px;"
    >
      <button
        v-if="!medicament._id"
        @click="saveMedicament"
        class="bg-dokBlue-ultra border-0 py-2 px-2 rounded-cu text-white cursor-pointer"
      >
        <i class="fas fa-plus"></i>
      </button>
    </td>
  </tr>
</template>
<script>
import { EventBus } from "@/event-bus";
const inputMedicaments = () =>
  import(
    "@/views/dashboard/consultations/components/prescriptions/layouts/inputMedicaments.vue"
  );

export default {
  props: ["medicament", "patient"],
  components: {
    inputMedicaments
  },

  data() {
    return {
      error: false,
      errorMessage: "",
      filds: {}
    };
  },
  mounted() {
    // //console.log(this.$medicamentUnits)
  },
  methods: {
    setMedicament(data) {
      this.medicament.name = data.name;
      this.medicament.drugId = data.objectID;
      this.$forceUpdate();
      EventBus.$emit("closeMedicamentInput", { name: data.name });
    },
    removeMedicament() {
      this.medicament.add = false;
      this.$store.dispatch("healthbook/REMOVE_PATIENT_MEDICAMENT", {
        patientId: this.patient._id,
        medicamentId: this.medicament._id,
        onData: data => {
          this.$emit("medicamentRemoved", this.medicament);
        }
      });
    },

    saveMedicament() {
      this.error = false;
      this.errorMessage = "";
      if (!this.medicament.name) {
        this.error = true;
        this.errorMessage = "Ce champ est obligatoire";
        return;
      }

      this.medicament.add = true;
      this.$store.dispatch("healthbook/SAVE_PATIENT_MEDICAMENT", {
        patientId: this.patient._id,
        data: this.medicament,
        onData: this.onData
      });
    },
    onData(data) {
      if (data.ok) {
        this.medicament._id = data.data._id;
      }
      this.$forceUpdate();
      this.$emit("medicamentSaved");
    }
  }
};
</script>

<style lang="scss">
.rmv-line-medicament {
  display: none;
}
.tr-medicament:hover {
  .rmv-line-medicament {
    position: absolute;
    top: calc(50% - 7.6px);
    right: 0px;
    display: inline-block;
  }
}
.frequence-radios input[type="radio"][disabled] {
  border: 2px solid blue;
}
</style>
